<template>
    <div class="row vert-offset-top-half ">
        <div class="row justify-content-center">

            <div class="col s12">
                <h5 class="text-align-center">AtYourService</h5>
            </div>
            <div v-show="selected_option === false" class="col s12 ">
                <div class="col s12 no-padding ">

                    <div class="col s12 vert-offset-top-1 vert-offset-bottom-1 text-align-center">
                        <h6>We focus on helping customers get their voices heard.</h6>
                        <h6>Find the business you wish to interact with and let them know what's on your mind.</h6>
                    </div>

                    <div class="col s12">
                        <nav class="vert-offset-bottom-1">
                            <div class="nav-wrapper white">
                                <form @submit.prevent="searchOrganisation">
                                    <div class="input-field">
                                        <input id="search" type="search" v-model="organisation_search_key" required autocomplete="off" placeholder="Type to search who you are looking for">
                                        <label class="label-icon" for="search"><i class="material-icons black-text">search</i></label>
                                        <i class="material-icons black-text search-close-btn" @click="clearSearch">close</i>
                                    </div>
                                </form>
                            </div>
                            <div v-if="hasSearchResults" class="row col s12 vert-offset-top-1 border-bottom-1 padding-bottom-half">
                                <button v-for="(result, index) in results" :key="index" class="btn white grey-text text-darken-2 col s12 font-10" @click="selectOption(result.alias)" >{{result.name}}</button>
                            </div>
                        </nav>

                        <div v-show="organisation == null && (typeof results === 'undefined' || results === null || results.length <= 0)" class="col s12 padding-top-half padding-bottom-half">
                            <div class="row vert-offset-top-half">
                                <h6>There was no organisation matching your search key.</h6>
                            </div>
                        </div>
                    </div>

                    <div class="col s12 text-align-center">
                        <a v-for="(item, index) in organisation"
                                 :key="index"
                                 @click="selectOption(item.alias)"
                                 class="clickable vert-offset-top-half margin-right-half underline grey-text text-darken-2">
                            {{ item.name}}
                        </a>
                    </div>
                    <div class="col s12 vert-offset-top-half padding-top-1 text-align-center">
                        <button class="btn btn-small black darken-2 font-10"
                                v-if="showNext"
                                @click.prevent="loadNext">
                            Load More
                        </button>
                    </div>



                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {mapState} from "vuex"
    import http from "../http-common"


    export default{
        components: {

            /* InputComponent*/
        },
        data()
        {
            return {
                organisation:[],
                component:"",
                pagination: null,
                links: null,
                organisation_search_key: "",
                disable:false,
                stage:[],
                results: [],
                selected_option: false,
                errors:[],
                searching: false,
                search_timer: null,
                search_key: null,
                organisations: {
                    base_url: "tipoff/organisations/list",
                }
            }
        },
        methods: {
            selectOption: function(_key)
            {
                this.selected_option = true;
                this.$router.push("/main/"+_key);
            },
            getOrganisations: function()
            {
                let _url = this.organisations.base_url;
                if(this.current_page > 0)
                {
                    _url += "?page="+(this.current_page+1);
                }

                this.$store.dispatch("START_PROCESSING");
                return new Promise(resolve => {
                    http({ url: _url, method: "POST", timeout: 30000 })
                        .then(resp => {
                            this.$store.dispatch("STOP_PROCESSING");

                            /*console.log("Response:" + JSON.stringify(resp));
                            console.log("token:" + JSON.stringify(resp.data));*/
                            let _listOrganisations = resp.data.data;
                            if(typeof _listOrganisations !== "undefined" && _listOrganisations !== null && _listOrganisations.length > 0)
                            {
                                for(let i=0;i<_listOrganisations.length;i++)
                                {
                                    this.organisation.push(_listOrganisations[i]);
                                }
                            }
                            this.links = resp.data.links;
                            this.pagination = resp.data.pagination;
                            /*console.log("Organisation: " + JSON.stringify(this.organisation));*/
                            console.log("LINKS: "+JSON.stringify(this.links));
                            resolve(resp);
                        })
                        .catch(response => {
                            /*console.log("ErrResponse: " + JSON.stringify(response));*/
                            this.$store.dispatch("STOP_PROCESSING");
                            let _arrMessages = new Array();
                            if(typeof  response.data !== 'undefined' && response.data !== null && typeof  response.data.messages !== 'undefined' && response.data.messages !== null)
                            {
                                _arrMessages = response.data.messages;
                            }
                            else if(typeof  response.message !== 'undefined' && response.message !== null)
                            {
                                _arrMessages.push(response.message);
                            }
                            else
                            {
                                _arrMessages.push("An error occurred, please try again later.");
                            }
                            this.$store.dispatch("GET_MESSAGES", _arrMessages);
                            resolve(response);
                        });
                });
            },
            clearSearch: function()
            {
                this.organisation_search_key = null;
            },

            loadNext: function() {
                if (this.showNext === true) {
                    /*this.getOrganisations(this.links.next);*/
                    this.getOrganisations();
                }
            },

            searchOrganisation: function(_withoutLoading)
            {
                if(typeof _withoutLoading === "undefined" || _withoutLoading === null || _withoutLoading !== true)
                {
                    this.$store.dispatch("START_PROCESSING");
                }
                this.searching = true;
                return new Promise(resolve => {
                    http({
                        url: "/tipoff/organisations/search",
                        method: "POST",
                        data: {
                            name: this.search_key
                        },
                        timeout: 30000
                    })
                        .then(resp => {
                            this.searching = false;
                            if(typeof _withoutLoading === "undefined" || _withoutLoading === null || _withoutLoading !== true)
                            {
                                this.$store.dispatch("STOP_PROCESSING");
                            }

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("token:" + JSON.stringify(resp.data));
                            this.results = resp.data.data;
                            console.log(
                                "Organisation: " + JSON.stringify(this.organisation)
                            );

                            if (this.organisation.length <= 0) {
                                this.no_results = true;
                            }
                            // you have your token, now log in your user :)
                            resolve(resp);
                        })
                        .catch(response => {
                            this.searching = false;
                            console.log("ErrResponse: " + JSON.stringify(response));
                            if(typeof _withoutLoading === "undefined" || _withoutLoading === null || _withoutLoading !== true)
                            {
                                this.$store.dispatch("STOP_PROCESSING");
                            }
                            let _arrMessages = new Array();
                            if(typeof  response.data !== 'undefined' && response.data !== null && typeof  response.data.messages !== 'undefined' && response.data.messages !== null)
                            {
                                _arrMessages = response.data.messages;
                            }
                            else if(typeof  response.message !== 'undefined' && response.message !== null)
                            {
                                _arrMessages.push(response.message);

                            }
                            else
                            {
                                _arrMessages.push("An error occurred, please try again later.");
                            }
                            this.$store.dispatch("GET_MESSAGES", _arrMessages);
                            resolve(response);
                        });
                });
            },
            checkCompatibility: function()
            {

                if(typeof navigator !== "undefined" && navigator !== null && typeof navigator.webkitGetUserMedia !== "undefined" && navigator.webkitGetUserMedia !== null)
                    navigator.getUserMedia = navigator.webkitGetUserMedia;
                else if(typeof navigator !== "undefined" && navigator !== null && navigator.mozGetUserMedia !== "undefined" && navigator.mozGetUserMedia !== null)
                    navigator.getUserMedia = navigator.webkitGetUserMedia;
                else if(typeof navigator !== "undefined" && navigator !== null && navigator.msGetUserMedia !== "undefined" && navigator.msGetUserMedia !== null)
                    navigator.getUserMedia = navigator.msGetUserMedia;
                else if(typeof navigator !== "undefined" && navigator !== null && typeof navigator.mediaDevices !== "undefined" && navigator.mediaDevices !== null && navigator !== null && navigator.mediaDevices.getUserMedia !== "undefined" && navigator.mediaDevices.getUserMedia !== null)
                    navigator.getUserMedia = navigator.mediaDevices.getUserMedia;
                else if(typeof window !== "undefined" && window !== null && typeof window.navigator !== "undefined" && window.navigator !== null)
                {
                    if(typeof window.navigator !== "undefined" && window.navigator !== null && typeof window.navigator.webkitGetUserMedia !== "undefined" && window.navigator.webkitGetUserMedia !== null)
                        navigator.getUserMedia = window.navigator.webkitGetUserMedia;
                    else if(typeof window.navigator !== "undefined" && window.navigator !== null && window.navigator.mozGetUserMedia !== "undefined" && window.navigator.mozGetUserMedia !== null)
                        navigator.getUserMedia = navigator.webkitGetUserMedia;
                    else if(typeof window.navigator !== "undefined" && window.navigator !== null && window.navigator.msGetUserMedia !== "undefined" && window.navigator.msGetUserMedia !== null)
                        navigator.getUserMedia = navigator.msGetUserMedia;
                    else if(typeof window.navigator !== "undefined" && window.navigator !== null && window.navigator.mediaDevices.getUserMedia !== "undefined" && window.navigator.mediaDevices.getUserMedia !== null)
                        navigator.getUserMedia = navigator.mediaDevices.getUserMedia;
                }
                if(typeof  navigator.getUserMedia === "undefined" || navigator.getUserMedia === null)
                {
                    return false;
                }

                return true;
            },
            checkOS: function()
            {
                this.OSName = "Unknown OS";
                if (navigator.appVersion.indexOf("Win")!=-1) this.OSName="Windows";
                if (navigator.appVersion.indexOf("Mac")!=-1) this.OSName="MacOS";
                if (navigator.appVersion.indexOf("X11")!=-1) this.OSName="UNIX";
                if (navigator.appVersion.indexOf("Linux")!=-1) this.OSName="Linux";
            },
        },




        computed: {
            ...mapState({
                messages: state => state.messages,
                actionName: state => state.actionName,
            }),
            phoneIsValid :function () {

                return typeof this.phone !== "undefined" && this.phone !== null && this.phone.isNumber() && this.phone.startsWith(7) && this.phone.length === 8;
            },
            showPrevious: function() {
                if (
                    typeof this.organisation === "undefined" ||
                    this.organisation === null ||
                    this.organisation.length <= 0
                ) {
                    return false;
                }

                if (typeof this.links === "undefined" || this.links === null) {
                    return false;
                }
                if (
                    typeof this.links.previous === "undefined" ||
                    this.links.previous === null
                ) {
                    return false;
                }
                return true;
            },
            showNext: function() {
                if (
                    typeof this.organisation === "undefined" ||
                    this.organisation === null ||
                    this.organisation.length <= 0
                ) {
                    return false;
                }
                if (typeof this.links === "undefined" || this.links === null) {
                    return false;
                }
                if (typeof this.links.next === "undefined" || this.links.next === null) {
                    return false;
                }
                return true;
            },
            hasLinks: function()
            {
                return (typeof this.links !== "undefined" && this.links !== null);
            },
            hasNextBtn: function()
            {
                return this.hasLinks && (typeof this.links.next !== "undefined" && this.links.next !== null);
            },
            hasPagination: function()
            {
                return (typeof this.pagination !== "undefined" && this.pagination !== null);
            },
            hasCurrentPage: function()
            {
                return this.hasPagination && (typeof this.pagination.current_page !== "undefined" && this.pagination.current_page !== null);
            },
            current_page: function()
            {
                if(this.hasCurrentPage)
                {
                    return this.pagination.current_page;
                }
                return null;
            },
            iOSDetected: function()
            {
                return (this.OSName === "MacOS");
            },
            hasSearchResults: function ()
            {
                return (typeof this.results !== "undefined" && this.results !== null);
            }
        },
        created() {
            this.getOrganisations();
        },

        watch: {
            organisation_search_key: function(_value)
            {
                this.search_key = _value;
                clearTimeout(this.search_timer);
                window.console.log("search key changed to: " + _value);
                if(typeof _value === "undefined" || _value === null || _value.trim().length < 2)
                {
                    this.results = null;
                    return false;
                }
                let _ms = 500;/*500 milliseconds*/
                let _thisObj = this;
                _thisObj.search_timer = setTimeout(function()
                {
                    _thisObj.searchOrganisation(true);
                }, _ms);

            },
            pagination: function(_value) {
                console.log("pagination watch");
                if (typeof _value === "undefined" || _value === null) return;
                //this.current_page = _value.current_page;
                this.per_page = _value.per_page;
            },
            no_results: function()
            {
                if (this.no_results) {
                    console.log("Organisations: " + this.organisation.length);
                    if (
                        typeof this.organisation == "undefined" ||
                        this.organisation == null ||
                        this.organisation.length <= 0
                    ) {
                        console.log("No Results watch");

                        this.getOrganisations();
                    }
                }
            }
        },
        mounted()
        {
            if (
                typeof this.organisation !== "undefined" &&
                this.organisation !== null &&
                this.organisation.length === 1
            ) {
                console.log("Organisation key: " + this.organisation[0].key);
                this.$store
                    .dispatch("SET_SELECTED_ORGANISATION", this.organisation[0].key)
            }
            /*this.compatibility = this.checkCompatibility();
             this.page_loaded = true;*/

        }
    }
</script>
<style scoped>
    .round {

        border-radius: 15px;
        border: 1px #00b0ff solid;
        padding: 5px 5px 5px 25px;

    }
    .btn-info {
        border: 2px solid black;
        background-color: white;
        border-color: rgba(113, 190, 255, 0.96);
        border-radius: 20px;
        color: #6eb8f6;

    }
    .btn-info:hover {
        background: #2196F3;
        color: white;
    }
    .searchbar{
        border-radius: 20px;
    }


</style>